<template>
  <div :class="['page-wrap', {'darkTheme' : darkTheme, 'page-wrap-scrollWrap' : $route.meta.scrollWrap, 'active': dataLoaded}]" id="pageWrap">
    <Teleport to="body">
      <div v-if="isMobile" :class="['horizontalPlaceholder', {'horizontalPlaceholder--dark' : darkTheme}]">
        <img v-if="darkTheme"  src="@/assets/img/owl-placeholder-dark.svg" alt="Совенок крутит телефон" class="horizontalPlaceholder__img">
        <img v-else src="@/assets/img/owl-placeholder.svg" alt="Совенок крутит телефон" class="horizontalPlaceholder__img">
        <div class="horizontalPlaceholder__text">  
          <p>
            Пожалуйста переверните телефон в вертикальное положение.
          </p>
          <p>
            Так ведь намного удобней ;)
          </p>
        </div>
      </div>
  </Teleport>
     <Transition name="slide-fade">
      <AppHeader v-if="!$route.meta.errorPage && !$route.meta.hideHeader" />
    </Transition>

    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition || 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>   

    <Transition name="slide-fade">
      <Nav v-if="!$route.meta.hideNavigation && !$route.meta.errorPage" />
    </Transition>

    <ModalsContainer />


    <Modal
        v-model="userInfo.bonus_given"
        @close="dailyClose"
        @confirm="dailyClose"
        :title="$t('common.dailyReward.congrats')"
        :btnText="$t('common.dailyReward.take')"
    >

      <div class="buy-popup-text" v-html='$t("common.dailyReward.text")'></div>
      <div class="buy-popup-reward">
        <div class="buy-popup-reward-title">{{ $t('common.crystals', 1) }}</div>
        <div class="buy-popup-reward-img">
          <img v-if="darkTheme" src="@/assets/img/crystall-popup-dark.svg" alt="Кристаллы"  width="2980" height="248" />
          <img v-else src="@/assets/img/crystall-popup.svg" alt="Кристаллы"  width="2980" height="248" />
        </div>
      </div>

    </Modal>

    <Modal
        v-model="rulesModal"
        @close="closePoUpModal"
        class="modal-wrapper info-modal"
      >
        <template #customHeader>
          <div class="info-modal-img"></div>
        </template>

        <div class="info-modal-content">
          <p>Пройдите как можно больше уровней и соберите все уникальные элементы пазла в течение месяца. Войдите в первую десятку собравших пазл и получите стакан из лимитированной коллекции билайна!</p>
        </div>

      </Modal>

    <AppFooter />
  </div>

</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'
  import { ModalsContainer } from 'vue-final-modal'
  import { useMainStore } from '@/store';
  import { useAuthStore } from '@/store/auth';
  import { useUserStore } from '@/store/user';
  import { mapActions, mapState } from 'pinia';
  import Cookies from 'js-cookie';
  import Nav from '@/components/Nav.vue'
  import AppFooter from '@/components/AppFooter.vue'
  import AppHeader from '@/components/AppHeader.vue'
  import Modal from '@/components/modals/Modal.vue'

  let sessionCookie;
  if (process.env.VUE_APP_SESSION_COOKIE_VALUE){
    sessionCookie = process.env.VUE_APP_SESSION_COOKIE;
  }


  export default {
    name: 'LayoutDefault',
    components: {
      Nav,
      AppFooter,
      AppHeader,
      ModalsContainer,
      Modal
    },
    data() {
      return {
        dailyModal: true,
        rulesModal: false,
        isMobile: false,
      };
    },
    computed: {
      ...mapState(useMainStore, ['modalVisible', 'modal', 'dailyReward', 'darkTheme']),
      ...mapState(useUserStore, ['userInfo','checkDaily', 'dataLoaded']),
    },
    async created() {
      const token = decodeURI(Cookies.get(sessionCookie));
      if(token !=='undefined' || token !=''){
        const result = await this.setToken(token);
        if (result) {
          await this.loadData();
          this.dailyModal = this.checkDaily
        }
        this.detectedMobileDevise();
      }
    },
    methods: {
      ...mapActions(useAuthStore, ['setToken', 'loadData']),
      ...mapActions(useUserStore, ['takeDaily', 'getUserInfo']),
      detectedMobileDevise() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        this.isMobile = regex.test(navigator.userAgent) ? true: false;
      },
      async dailyClose() {
        await this.takeDaily();
        this.rulesModal = true;
      },
      closePoUpModal() {
        this.rulesModal = false;
      },
    }
  };
</script>
